import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F8F7F7',
      neutralLight3: '#4B1D28',
      neutralDark5: '#A0A0A0',
      neutralDark4: '#938A83',
      neutralDark3: '#7B7672',
      neutralDark2: '#3B3733',
      neutralDark1: '#000000',
      primaryLight: '#5A2C37',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'marlide-display-variable', sans-serif",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
  font-variation-settings: 'wght' 300;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark4};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.9375rem;
    letter-spacing: 0.025em;
    line-height: 1.3125rem;
    margin-bottom: 2.25rem;

    @media (max-width: 1199px) {
      margin-bottom: 1rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 5rem;
      font-variation-settings: 'wght' 400;
      line-height: 4.625rem;

      @media (max-width: 1199px) {
        font-size: 3.75rem;
        font-variation-settings: 'wght' 300;
        line-height: 4rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 4.5rem;
      line-height: 4.5rem;

      @media (max-width: 1199px) {
        font-size: 2.75rem;
        line-height: 3.25rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 4.25rem;

      @media (max-width: 74.9375rem) {
        font-size: 2.25rem;
        line-height: 2.625rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.625rem;

      @media (max-width: 74.9375rem) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark3};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1.0625rem;
    font-weight: 300;
    line-height: 1.5625rem;

    @media (max-width: 1199px) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
